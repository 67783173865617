export const MIN_PASSWORD_LENGTH = 6;

export default {
    validatePostCode: {
        validate: (value) => {
            const {value: val, attributes: {countrycode: country} = {}} = value;
            const countryCode= country?.value;

            // Check if the country code was selected and if we have an actual postcode
            if (!countryCode || !val) {
                return false;
            }

            // Map of EU country codes to their postal code length and type (numeric or alphanumeric)
            const postalCodeFormat = {
                AT: {length: 4, format: /^\d{4}$/},
                BE: {length: 4, format: /^\d{4}$/},
                BG: {length: 4, format: /^\d{4}$/},
                DK: {length: 4, format: /^\d{4}$/},
                ES: {length: 5, format: /^\d{5}$/},
                FR: {length: 5, format: /^\d{5}$/},
                IT: {length: 5, format: /^\d{5}$/},
                LU: {length: 4, format: /^\d{4}$/},
                PL: {length: 5, format: /^\d{5}$/},
                PT: {length: 7, format: /^\d{7}$/},
                RO: {length: 6, format: /^\d{6}$/},
                SE: {length: 5, format: /^\d{5}$/},
                DE: {length: 5, format: /^\d{5}$/}
            };

            // Check if the length of the postal code matches the required length
            if (val.length !== postalCodeFormat[countryCode]?.length) {
                return false;
            }

            // Check if the postal code matches the required format
            if (!postalCodeFormat[countryCode].format.test(val)) {
                return false;
            }

            return true;
        },
        message: __('Please enter a valid postal code')
    },
    pfpjValidation: {
        validate: ({ value }) => {
            const trimmedValue = value.replace(/\s+/g, '');
            return trimmedValue.length >= 3;
        },
        message: __('This is a required field. Please enter at least 3 characters')
    },
    email: {
        validate: ({ value }) => value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i),
        message: __('Wrong e-mail')
    },
    password: {
        validate: ({ value }) => value.match(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$/), // six char, at least: 1 uppercase, 1 lowercase, 1 digit
        message: __('The password must contain at least 6 characters, including at least a number, an uppercase letter and a lowercase letter.')
    },

    telephoneByCountry: {
        validate: ({ value, attributes: { countrycode: country } = {} }) => {
            const telephoneFormat = {
                AT: /^\+43\d{10}$/,       // Austria
                BE: /^\+32\d{8,10}$/,        // Belgium
                BG: /^\+359\d{9}$/,       // Bulgaria
                DK: /^\+45\d{8}$/,        // Denmark
                ES: /^\+34\d{9}$/,        // Spain
                FR: /^\+33\d{9}$/,        // France
                IT: /^\+39\d{9,10}$/,       // Italy
                LU: /^\+352\d{8,10}$/,       // Luxembourg
                PL: /^\+48\d{9}$/,        // Poland
                PT: /^\+351\d{9}$/,       // Portugal
                RO: /^\+?\d{10,11}$/,        // Romania
                SE: /^\+46\d+$/,          // Sweden
                DE: /^\+49\d{10}$/,       // Germany
            };

            const countryCode = country?.value;
            const phoneFormat = telephoneFormat[countryCode];
            const cleanedValue = value.replace(/[\s-]/g, '');

            // Check if the country code was selected and if we have a valid telephone number format
            if (!countryCode || !phoneFormat || !cleanedValue) {
                return false;
            }
            return phoneFormat.test(cleanedValue);
        },
        message: __('Phone number is invalid!')
    },
    telephone: {
        validate: ({ value }) => value.match(/^\+?\d{10,11}$/),
        message: 'Numărul trebuie să conțină 10 cifre și să fie de forma 07xxxxxxxx sau +407xxxxxxxx'
    },
    telephoneMin10Max20: {
        validate: ({ value }) => value.match(/\+(\d{10,20})$/),
        message: __('Phone number is invalid!')
    },
    notEmpty: {
        validate: ({ value }) => value.length > 0,
        message: __('This field is required!')
    },
    checked: {
        validate: ({ value }) => value === "true",
        message: __('This field is required!')
    },
    password_match: {
        validate: ({ value }, { password }) => {
            const { current: { value: passwordValue } } = password || { current: {} };
            return value === passwordValue;
        },
        message: __('The passwords do not match.')
    },
    validDate: {
        validate: ({ value }) => (new Date(value.slice(0, 10).split('/').reverse().join('/')) !== 'Invalid Date') && !isNaN(new Date(value.slice(0, 10).split('/').reverse().join('/'))),
        message: __('Invalid date.')
    },
    dateBetween: {
        validate: ({ value }) => {
            const from = new Date('1920-01-01');
            const to = new Date('2010-01-01');
            const check = new Date(value.split('/').reverse().join('-'));

            return (check > from && check < to);
        },
        message: __('Date must be between 1920 and 2010.')
    },
    lengthBetween2And15: {
        validate: ({ value }) => value.length >= 2 && value.length <= 15,
        message: __('Please enter between 2 and 15 characters.')
    },
    lengthEmail: {
        validate: ({ value }) => value.length >= 5 && value.length <= 80,
        message: __('Please enter a valid email address.')
    },
    lengthBetween5And100: {
        validate: ({ value }) => value.length >= 5 && value.length <= 100,
        message: __('Please enter at least 5 characters.')
    },
    lengthBetween2And20: {
        validate: ({ value }) => value.length >= 2 && value.length <= 20,
        message: __('Please enter at least 2 characters.')
    },
    lengthMax6: {
        validate: ({ value }) => value.length <= 6,
        message: __('Please enter a maximum of 6 characters.')
    },
    lengthMax10: {
        validate: ({ value }) => value.length <= 10,
        message: __('Please enter a maximum of 10 characters.')
    },
    lengthMax250: {
        validate: ({ value }) => value.length <= 250,
        message: __('Please enter a maximum of 250 characters.')
    }
};
