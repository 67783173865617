import SourceCmsBlockContainer from 'SourceComponent/CmsBlock/CmsBlock.container';

export class CmsBlockContainer extends SourceCmsBlockContainer {
    containerProps = () => {
        const { cmsBlock } = this.state;
        const { collapsible, mix } = this.props;
        return {
            cmsBlock,
            collapsible,
            mix
        };
    };
}

export default CmsBlockContainer;
