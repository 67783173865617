import PropTypes from 'prop-types';
import SourceCmsBlock from 'SourceComponent/CmsBlock/CmsBlock.component';
import Html from 'Component/Html';
import ExpandableContent from 'Component/ExpandableContent';
import { ChildrenType } from 'Type/Common';

export class CmsBlock extends SourceCmsBlock {
    static propTypes = {
        cmsBlock: PropTypes.shape({
            identifier: PropTypes.string,
            content: PropTypes.string,
            disabled: PropTypes.bool
        }),
        children: ChildrenType,
        collapsible: PropTypes.bool,
        mix: PropTypes.string
    };

    static defaultProps = {
        cmsBlock: {},
        children: [],
        collapsible: false,
        mix: ''
    };

    render() {
        const {
            cmsBlock: {
                identifier, title, content, disabled
            },
            collapsible,
            mix
        } = this.props;

        if (disabled) {
            return null;
        }

        if (identifier === undefined) {
            return this.renderPlaceholder();
        }

        if (collapsible) {
            return (
                <ExpandableContent
                  heading={ title }
                  mix={ mix ? { block: mix } : null }
                >
                    <Html content={ content } />
                </ExpandableContent>
            );
        }

        return (
            <div
              block="CmsBlock"
              elem="Wrapper"
            >
                <Html content={ content } />
            </div>
        );
    }
}

export default CmsBlock;
