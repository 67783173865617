import { MetaContainer as SourceMetaContainer } from 'SourceComponent/Meta/Meta.container';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export const mapStateToProps = (state) => ({
    default_description: state.MetaReducer.default_description,
    default_keywords: state.MetaReducer.default_keywords,
    default_title: state.MetaReducer.default_title,
    canonical_url: state.MetaReducer.canonical_url,
    title_prefix: state.MetaReducer.title_prefix,
    title_suffix: state.MetaReducer.title_suffix,
    description: state.MetaReducer.description,
    keywords: state.MetaReducer.keywords,
    title: state.MetaReducer.title,
    robots: state.MetaReducer.robots,
    og_image: state.MetaReducer.og_image,
    og_type: state.MetaReducer.og_type
});

export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        default_description: PropTypes.string,
        default_keywords: PropTypes.string,
        default_title: PropTypes.string,
        canonical_url: PropTypes.string,
        title_prefix: PropTypes.string,
        title_suffix: PropTypes.string,
        description: PropTypes.string,
        keywords: PropTypes.string,
        title: PropTypes.string,
        robots: PropTypes.string,
        og_image: PropTypes.string,
        og_type: PropTypes.string
    };

    static defaultProps = {
        default_description: '',
        default_keywords: '',
        default_title: '',
        canonical_url: '',
        title_prefix: '',
        title_suffix: '',
        description: '',
        keywords: '',
        title: '',
        robots: 'index, follow',
        og_image: '',
        og_type: ''
    };

    _getOgType() {
        const { og_type } = this.props;

        return og_type || null;
    }

    _getOgImage() {
        const { og_image } = this.props;

        return og_image || null;
    }

    _getRobots() {
        const { robots } = this.props;

        return robots;
    }

    _getMetadata() {
        const meta = {
            title: this._getTitle(),
            description: this._getDescription(),
            keywords: this._getKeywords(),
            robots: this._getRobots(),
            ['og:title']: this._getTitle(),
            ['og:description']: this._getDescription(),
            ['og:image']: this._getOgImage(),
            ['og:type']: this._getOgType()
        };

        return this._generateMetaFromMetadata(meta);
    }

    _generateMetaFromMetadata(metadata, param = 'property') {
        return Object.entries(metadata).reduce((acc, [key, value]) => (
            value
                ? [...acc, { [param]: key, content: `${value}` }]
                : acc
        ), []);
    }
}

export default connect(mapStateToProps)(MetaContainer);
